import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { hot } from "react-hot-loader";
import configureStore, {
  history,
  sagaMiddleware,
} from "./config/store/configureStore";
import "./App.scss";
import MainLayout from "./layoutRouter/MainLayout/index";
import rootSaga from "./config/model/rootSaga";
import { StyleComponentConnected } from "./scss/StyleComponent";
import "./utils/Translations";
import { loadReCaptcha } from "react-recaptcha-v3";
import { RECAPTCHA_SITE_KEY } from "./config/constant/base";
//import translations from "./utils/translations/translations";
import { LangContextTheme } from './context/langContext';

export const store = configureStore();

sagaMiddleware.run(rootSaga);

const App =()=> {
  useEffect(() => {
    const getNonce =() =>{
      const metaTag = document.querySelector('meta[name="csp-nonce"]');
      return metaTag ? metaTag.getAttribute('content'):'';
    };
    const nonce =getNonce();
    loadReCaptcha(RECAPTCHA_SITE_KEY,{nonce});
    /*const script = document.createElement('script');
    script.src = `https://www.recaptcha.net/recaptcha/api.js?onload=onLoadCaptchaV3Callback&render=${RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;
    if(nonce){
      script.setAttribute('nonce', nonce);
    }
    document.body.appendChild(script);
    return() => {
      document.body.removeChild(script);
    };*/
  }, [])

    return (
      <Provider store={store}>
        <LangContextTheme>
          <StyleComponentConnected />
          <MainLayout history={history} />
        </LangContextTheme>
      </Provider>
    );

}

export default hot(module)(App);
